<script>
import Button from '@/components/Button';
import NftCard from '@/components/NftCard';

export default {
  name: 'index',
  components: { NftCard, Button },
  computed: {
    account() {
      return this.$store.state.User;
    },
    pools() {
      return this.$store.state.Pools.list.nft
        ? this.$store.state.Pools.list.nft.map((pool) => ({ ...pool, type: 'nft' })).slice().reverse()
        : [];
    },
    chainId() {
      return this.$store.state.User.chainId;
    },
  },
};
</script>

<template>
  <div class="page-content">
    <div class="main-wrap main-wrap--narrow" v-if="chainId === 56 || chainId === 97">
      <h1 class="page-title">{{ $t('eth-only') }}</h1>
      <v-row justify="center">
        <Button to="/">
          {{ $t('not-found.button') }}
        </Button>
      </v-row>
    </div>
    <div class="main-wrap main-wrap--narrow" v-else>
      <h1 class="page-title">{{ $t('nft.title') }}</h1>
      <v-row justify="center" class="page-content__btns">
        <Button href="https://nft.spaceswap.app/">
          {{ $t('nft.how-it-works') }}
        </Button>
        <Button href="https://nftstars.app/">
          {{ $t('nft.marketplace') }}
        </Button>
      </v-row>
      <v-row justify="center">
        <v-col
          md="4"
          sm="4"
          xs="12"
          v-for="(product, index) in pools.filter((p) => p.isMain)"
          :key="index"
        >
          <NftCard
            :product="product"
            :showLabel="false"
          >
          </NftCard>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          md="4"
          sm="4"
          xs="12"
          v-for="(product, index) in pools.filter((p) => !p.isMain)"
          :key="index"
        >
          <NftCard
            :product="product"
            :showLabel="false"
          >
          </NftCard>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<style lang="scss"></style>
