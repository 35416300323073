<script>
import Button from './Button';

export default {
  name: 'NftCard',
  components: { Button },
  props: {
    product: {
      required: true,
    },
    disableBtn: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    productRoute() {
      return this.product.type && this.product.type !== ''
        ? `pools-${this.product.type}-pid`
        : undefined;
    },
  },
};
</script>
<template>
  <div class="product-card main-block">
    <div>{{ product.apy }}</div>
    <div class="product-card__logo">
      <img
        :alt="product.name"
        :src="require(`../assets/img/nft/${ product.icon }.png`)"
      >
    </div>
    <div class="product-card__header">
      <span class="product-card__title">{{ product.name }}</span>
      <span class="product-card__name" v-if="!product.active"> Not active </span>
      <span class="product-card__name" v-else>{{ $t('get-milk') }}</span>
    </div>
    <slot></slot>
    <Button
      v-if="!disableBtn"
      :to="{ name: productRoute, params: { id: product.id } }"
      :disabled="!productRoute"
    >
      {{ $t('product-card.button') }}
    </Button>
  </div>
</template>

<style lang="scss">
.product-card {
  font-size: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.8em 3.3em 2.3em;

  &.accent {
    box-shadow: none;
  }

  @media screen and (max-width: $breakpoint-lg) {
    font-size: 1.5rem;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    margin: 0 auto;
    display: block;
    font-size: 1.3rem;
    padding: 3em 2.6em 2.3em;
  }

  &__title {
    text-transform: uppercase;
    min-height: 54px;
  }

  &__markers {
    position: absolute;
    top: 2em;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0 3.3em;
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: flex-end;
      top: 1.6em;
      padding: 0 2em;
    }
  }

  &__uni {
    right: 3.2em;
    width: 4.2em;
    border-radius: 50%;
    box-shadow: 1em 1em 1.8em rgba(222, 222, 222, 0.25),
    .2em .2em 1.8em rgba(212, 202, 205, 0.2),
    -1em -1em 1.4em #FFFFFF,
    1em 1em 1.8em rgba(181, 155, 155, 0.25),
    inset .2em .2em 6px #FFFFFF,
    inset -.1em -.1em .2em rgba(181, 155, 155, 0.2);
    margin-left: auto;
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 1em;
      width: 3.4em;
    }
  }

  &__hot {
    width: 3em;
    @media screen and (max-width: $breakpoint-sm) {
      left: auto;
      right: 0;
      width: 2.2em;
    }
  }

  &__logo {
    padding-top: 53%;
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    @media screen and (max-width: $breakpoint-sm) {
      padding-top: 0;
      position: absolute;
      height: 7em;
      width: 10em;
      left: 0;
      top: 2.5em;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      max-width: 100%;
      @media screen and (max-width: $breakpoint-sm) {
        position: initial;
        display: block;
        max-width: none;
        transform: none;
        margin: 0 auto;
      }
    }
  }

  &__header {
    text-align: center;
    @media screen and (max-width: $breakpoint-sm) {
      margin-left: 7em;
      text-align: left;
    }
  }

  &__title {
    font-size: 3em;
    line-height: 120%;
    margin-bottom: .4em;
    font-weight: bold;
    display: block;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: 2em;
    }
  }

  &__name {
    display: block;
    font-size: 1.5em;
    font-weight: 500;
    color: $grey-font-color;
    height: 5em;
    @media screen and (max-width: $breakpoint-sm) {
      height: 3.5em;
    }
  }

  &__param {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 1.3em;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: uppercase;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 1em;
    }

    span:first-child {
      color: $grey-font-color;
    }
  }

  .btn {
    width: 100%;
  }
}

.accent {
  .accent-box {
    z-index: -1;
    padding: 5px;
    $size: -5px;
    position: absolute;
    top: $size;
    left: $size;
    right: $size;
    bottom: $size;
    background: transparent;
    overflow: hidden;
    filter: blur(8px);

    > div {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 170%;
      padding-top: 170%;
      transform: translate(-50%, -50%);
      background: linear-gradient(
          0,
          #FFACC5 0%,
          #FFC1B7 20%,
          #FFE6AA 40%,
          #A7EFF6 60%,
          #FFE6AA 80%,
          #FFACC5 100%
      );
      background-size: 300% 300%;
      animation: rainbowRotate 10s linear infinite;
    }
  }
}

@keyframes rainbowRotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
